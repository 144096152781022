<template>
  <div>
    <div style="width: 100%">
      <img style="width: 100%" src="./img/顶部背景.png" alt="" />
    </div>

    <div class="bottomCss">
      <div class="xiangqing">
        <!-- 标题图片 -->
        <div class="imgCss">
          <div>{{ this.data.name }}</div>
          <div class="tupailie">
            <img class="datu" :src="this.datu" alt="" />
            <div class="xtpl">
              <div v-for="(item,index) in this.imgdata" :key="index">
                <img
                  @click="qiehuan(index)"
                  class="xioatu"
                  :src="item.url"
                  alt=""
                />
              </div>
              <!-- <img class="xioatu" :src="this.imgdata[2].url" alt="" />
              <img class="xioatu" :src="this.imgdata[3].url" alt="" /> -->
            </div>
          </div>
        </div>
        <!-- 产品简介 -->
        <div class="jianjie">
          <div
            style="
              margin-top: 42px;
              font-size: 30px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #000000;
            "
          >
            产品介绍
          </div>
          <div style="display: flex; margin-top: 24px; margin-bottom: 24px">
            <div
              v-for="(item, index) in data.biaioqian"
              :key="index"
              class="biaoqian"
            >
              {{ item.bq }}
            </div>
          </div>
          <div
            style="
              font-size: 20px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.6);
              line-height: 38px;
              margin-bottom: 36px;
            "
          >
            {{ this.data.jieshao }}
          </div>
        </div>
        <!-- 产品优点 -->
        <div class="youdian">
          <div
            style="
              margin-top: 36px;
              font-size: 30px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #000000;
            "
          >
            产品优点
          </div>
          <div class="ydlb">
            <div class="ydnr" v-for="(item,index) in this.youdianData" :key="index">{{item.yd}}</div>
          </div>
        </div>
        <!-- 关键参数 -->
        <div class="canshu">
          <div
            style="
              margin-top: 36px;
              font-size: 30px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #000000;
            "
          >
            关键参数
          </div>
          <div class="csimg">
            <img style="width: 100%;" :src=this.canshuData alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      youdianData:[
        {yd:"8-12kw输出"},
        {yd:"IP65防尘防水"},
        {yd:"自动管理电池充放电"},
        {yd:"可自由设置输入电源的优先级"},
        {yd:"兼容铅酸和锂离子电池"},
        {yd:"全方位安全保护：过压、过流、过温、短路"},
        {yd:"支持柴油发电机输入源"},
        {yd:"电池反向连接保护，反功率控制功能"},
      ],
      data: {
        name: "三相混合式逆变器",
        biaioqian: [{ bq: "IP65防尘防水" }, { bq: "MPPT电压范围宽" }, { bq: "多种运行方式" }, { bq: "保护功能齐全" }],
        jieshao:
          "该系列产品旨在提高业主的能源独立性，基于使用时间和峰谷电价进行能源管理，能显著减少从公共电网购买的电量，并优化能源使用率。",
        url: require("@/views/product/img/三相混合逆变器.png"),
      },
      datu:require("@/views/product/img/三相混合逆变器.png"),
      canshuData:require("./img/三相混合式逆变器.png"),
      imgdata: [
        { url: require("@/views/product/img/三相混合逆变器.png") },
        { url: require("@/views/product/img/三相.png") },
        { url: require("@/views/product/img/三相混合逆变器.png") },
      ],

    };
  },
  methods: {
    qiehuan(index) {
      if (index==0) {
        this.datu=this.imgdata[0].url
      }else if (index==1) {
        this.datu=this.imgdata[1].url
      }else if (index==2) {
        this.datu=this.imgdata[2].url
      }
    },
  },
  mounted() {
    console.log(this.$route.query.key, "xxxxxxxxxxx");
    // this.data = this.$route.query.key;
    // this.datu=this.$route.query.key.url
  },
};
</script>

<style scoped>
.bottomCss {
  width: 100%;
  height: 100%;
  background: #f2f5f8;
  display: flex;
  justify-content: center;
}
.xiangqing {
  width: 69%;
  /* height: 3147px; */
  background: #ffffff;
  margin-top: 4%;
  margin-bottom: 4%;
}
.imgCss {
  font-size: 38px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 54px;
}
.datu {
  width: 500px;
  height: 500px;
  margin-bottom: 16px;
  margin-top: 42px;
}
.tupailie {
  display: flex;
  flex-direction: column;
}
.xtpl {
  display: flex;
  justify-content: space-evenly;
}
.xioatu {
  width: 110px;
  height: 110px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px 12px 12px 12px;
}
.xioatu:hover {
  width: 110px;
  height: 110px;
  border: 2px solid rgba(9, 64, 253, 1);
  border-radius: 12px 12px 12px 12px;
}
.jianjie {
  margin: 0px 64px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.youdian {
  margin: 0px 64px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.canshu {
  margin: 0px 64px 64px 64px;
}
.biaoqian {
  margin-right: 12px;
  height: 36px;
  background: rgba(9, 64, 253, 0.1);
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  border-radius: 6px 6px 6px 6px;
  color: #0940fd;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}
.ydlb {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 36px;
}
.ydnr {
  width: 50%;
  font-size: 20px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  margin-top: 24px;
}
.ydnr::before {
  content: "";
  display: flex;
  width: 9px;
  height: 9px;
  background: #0940fd;
  border-radius: 50%;
  margin-right: 16px;
}
.csimg {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>